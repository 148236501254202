<template>
  <component
    :is="props.type === 'a' ? 'a' : 'button'"
    :title="props.title"
    :href="props.href"
    :target="props.target"
    :class="classListBox"
    :type="props.type === 'a' ? '' : 'button'"
    style="box-shadow: 0 0 1px rgba(0, 0, 0, 0.5)"
    @click="onClick"
  >
    <div :class="classListInner">
      <slot></slot>
    </div>
  </component>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    // Put component in disabled mode
    disable?: boolean

    // The text that will be shown in the tooltip
    title?: string

    type?: 'button' | 'a' | 'submit'

    // Native <a> link href attribute; Type needs to be 'a'
    target?: '_blank' | '_self' | '_parent' | '_top'

    //style of the button
    mode?:
      | 'primary_full'
      | 'primary_border'
      | 'secondary_full'
      | 'secondary_border'
      | 'colorful_full'
      | 'colorful_border'
  }>(),
  {
    type: 'button',
    disable: false,
    mode: 'primary_full'
  }
)

const classes = {
  basic: {
    box: 'rounded-[8px] p-px',
    inner: 'rounded-[7px] px-3 py-1.5 text-sm',
    enabled: '',
    disabled: 'cursor-not-allowed opacity-50 cursor-custom'
  },
  enabled: {
    box: {
      primary: 'bg-primary-600',
      secondary: 'bg-secondary-600',
      colorful:
        'bg-gradient-to-r from-[#4797F5] via-[#6472F1] to-[#3AD8D8] avv-primary-bg-text'
    },
    full: {
      primary: 'bg-transparent avv-primary-bg-text hover:bg-primary-200',
      secondary: 'bg-transparent avv-primary-bg-text hover:bg-secondary-400',
      colorful: 'bg-transparent avv-primary-bg-text hover:bg-white/25'
    },
    border: {
      primary: 'avv-white-bg-color text-primary-600 hover:bg-primary-50',
      secondary: 'avv-white-bg-color text-secondary-600 hover:bg-secondary-100',
      colorful: 'avv-white-bg-color text-primary-600 hover:bg-white/95'
    }
  },
  disabled: {
    box: {
      primary: 'bg-primary-100',
      secondary: 'bg-secondary-300',
      colorful: 'bg-gradient-to-r from-[#4797F5] via-[#6472F1] to-[#3AD8D8]'
    },
    full: {
      primary: 'bg-transparent avv-primary-bg-text',
      secondary: 'bg-transparent avv-primary-bg-text',
      colorful: 'bg-transparent avv-primary-bg-text'
    },
    border: {
      primary: 'avv-white-bg-color text-primary-100',
      secondary: 'avv-white-bg-color text-secondary-300',
      colorful: 'avv-white-bg-color text-primary-600'
    }
  },
  secondary_full:
    'bg-secondary-600 avv-secondary-bg-text hover:bg-secondary-400',
  secondary_border:
    'border border-secondary-600 text-secondary-600 hover:bg-secondary-100',
  colorful_full:
    'bg-gradient-to-r from-[#4797F5] via-[#6472F1] to-[#3AD8D8] avv-primary-bg-text hover:opacity-25',
  colorful_border: 'border colorful_border text-primary-600 hover:opacity-25'
}
const modes_disabled = {
  secondary_full: 'bg-secondary-300 avv-secondary-bg-text',
  secondary_border: 'border border-secondary-300 text-secondary-300',
  colorful_full:
    'bg-gradient-to-r from-[#4797F5] via-[#6472F1] to-[#3AD8D8] avv-primary-bg-text',
  colorful_border: 'border colorful_border text-primary-600'
}
const modeColor = props.mode.split('_')[0]
const modeStyle = props.mode.split('_')[1]
const basicKlassesBox = 'rounded-[8px] p-px'
const basicKlassesInner = 'rounded-[7px] px-3 py-1.5 text-sm'
const basicKlasses_disabled = 'cursor-not-allowed opacity-50 cursor-custom'
const classListBox = computed(() => {
  const disabled = props.disable ? 'disabled' : 'enabled'
  return [
    classes.basic.box,
    classes.basic[disabled],
    classes[disabled].box[modeColor]
  ].join(' ')
})
const classListInner = computed(() => {
  const disabled = props.disable ? 'disabled' : 'enabled'
  return [classes.basic.inner, classes[disabled][modeStyle][modeColor]].join(
    ' '
  )
})

const emit = defineEmits<{
  (e: 'click', event?: MouseEvent): void
}>()

const onClick = (e?: MouseEvent) => {
  if (props.disable) return

  emit('click', e)
}
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'PreStyleButton' })
</script>
<style>
.colorful_border {
  border-image: linear-gradient(to right, #4797f5, #6472f1, #3ad8d8) 1;
}
</style>
